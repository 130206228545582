import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import styles from './links.module.css'
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import LinkedIn from "../../assets/icons/linkedIn.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Mastodon from "../../assets/icons/mastodon.svg";
import Resume from "../../assets/icons/resume.svg";
// import Mail from "../../assets/icons/mail.svg";

export default () => (
  <div className={styles.links}>

    <OutboundLink href="https://facebook.com/rafeed" rel="noopener noreferrer" target="_blank" aria-label="Link to my Facebook profile">
      <Facebook alt="Facebook icon" />
    </OutboundLink>

    <OutboundLink href="https://instagram.com/rafeed" rel="noopener noreferrer" target="_blank" aria-label="Link to my Instagram profile">
      <Instagram alt="Instagram icon" />
    </OutboundLink>

    <OutboundLink href="https://www.linkedin.com/in/rafeed" rel="noopener noreferrer" target="_blank" aria-label="Link to my LinkedIn profile">
      <LinkedIn alt="LinkedIn icon" />
    </OutboundLink>

    <OutboundLink href="https://www.twitter.com/rafeed" rel="noopener noreferrer" target="_blank" aria-label="Link to my Twitter profile">
      <Twitter alt="Twitter icon" />
    </OutboundLink>

    <OutboundLink href="https://mastodon.social/@rafeed" rel="noopener noreferrer" target="_blank" aria-label="Link to my Mastodon profile">
      <Mastodon alt="Mastodon icon" />
    </OutboundLink>

    <OutboundLink href="https://drive.google.com/file/d/1DVl02k1uOrgOESPUFmhBR4JDgr9TVl1-/view?usp=sharing" rel="noopener noreferrer" target="_blank" aria-label="Link to my Resume">
      <Resume alt="Resume icon" />
    </OutboundLink>

{    // <OutboundLink href="mailto:rafeed@me.com" rel="noopener noreferrer" aria-label="Email me!">
    //   <Mail alt="Mail icon" />
    // </OutboundLink>
}    

  </div>
)
