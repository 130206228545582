import React from 'react'
import styles from './about.module.css'
import ProfilePic from "../../assets/images/profile-coffee.jpg"

export default () => (
  <>
    <div className={styles.body}>
    <img className={styles.profile} src={ProfilePic} alt="Profile of Rafeed" />
      <h1 className={styles.headline}>Rafeed Chaudhury</h1>
      <p className={styles.underline}>
        I'm a <strong>TPM</strong> and <strong>Biomedical Engineer</strong> at <a href='https://google.com'>Google</a>.
      </p>
    </div>
  </>
)


